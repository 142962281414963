:root {
  --spotlight-position: 50%;
  --spotlight-back-color: theme('colors.gray.900');
  --spotlight-size: 120px;
}

.user-card-grid-spotlight-mask {
  width: 100%;
  height: 100%;
  background-color: var(--spotlight-back-color);
  mask: radial-gradient(
    circle at var(--spotlight-position) 50%,
    transparent var(--spotlight-size),
    var(--spotlight-back-color) calc(var(--spotlight-size) * 1.7)
  );
}
